var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.campus", true, "Sedes"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.campus',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_campus'),expression:"'mesh.add_campus'"},{name:"b-modal",rawName:"v-b-modal.new-campus-modal",modifiers:{"new-campus-modal":true}}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
          'mesh.campus',
          false,
          'Sede'
        )}`,"text_button":`Agregar`,"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),(_vm.campusList)?_c('GenericBTable',{attrs:{"items":_vm.campusList,"fields":_vm.fields_campuses,"pagination":_vm.campusList.length,"filterCustom":_vm.filterCustom,"primary-key":"id","filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":'campus',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(school)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getInstitutionName(row.item.school))+" ")]}},{key:"cell(address_line)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getAddressName(row.item.address))+" ")]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('b-modal',{attrs:{"id":`edit-campus-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames('mesh.campus', false, 'Sede')}`,"size":"lg","hide-footer":""}},[_c('CampusForm',{attrs:{"Campus":row.item},on:{"updated":_vm.slotUpdatedCampus,"created_address":_vm.slotCreatedAddress,"updated_address":_vm.slotUpdatedAddress}})],1),_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_campus'),expression:"'mesh.change_campus'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
          'mesh.campus',
          false,
          'Sede'
        )}`,"click_button":() => _vm.$bvModal.show(`edit-campus-modal-${row.item.id}`),"icon":'square'}}),_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_campus'),expression:"'mesh.delete_campus'"}],attrs:{"click_button":() => _vm.askForDeleteCampus(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
          'mesh.campus',
          false,
          'Sede'
        )}`,"icon":'trash'}})]}}:null],null,true)}):_vm._e(),_c('div',[_c('b-modal',{attrs:{"id":"new-campus-modal","title":`Crear ${_vm.$getVisibleNames('mesh.campus', false, 'Sede')}`,"size":"lg","hide-footer":""}},[_c('CampusForm',{on:{"created_address":_vm.slotCreatedAddress,"created":_vm.slotCreatedCampus}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }